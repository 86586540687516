// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-isco-08-codelist-en-js": () => import("./../src/pages/isco-08/codelist.en.js" /* webpackChunkName: "component---src-pages-isco-08-codelist-en-js" */),
  "component---src-pages-isco-08-codelist-es-js": () => import("./../src/pages/isco-08/codelist.es.js" /* webpackChunkName: "component---src-pages-isco-08-codelist-es-js" */),
  "component---src-pages-isco-08-codelist-fr-js": () => import("./../src/pages/isco-08/codelist.fr.js" /* webpackChunkName: "component---src-pages-isco-08-codelist-fr-js" */),
  "component---src-templates-about-js": () => import("./../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-all-tags-js": () => import("./../src/templates/allTags.js" /* webpackChunkName: "component---src-templates-all-tags-js" */),
  "component---src-templates-artworks-intro-js": () => import("./../src/templates/artworks-intro.js" /* webpackChunkName: "component---src-templates-artworks-intro-js" */),
  "component---src-templates-artworks-js": () => import("./../src/templates/artworks.js" /* webpackChunkName: "component---src-templates-artworks-js" */),
  "component---src-templates-artworks-portfolio-js": () => import("./../src/templates/artworks-portfolio.js" /* webpackChunkName: "component---src-templates-artworks-portfolio-js" */),
  "component---src-templates-artworks-simple-js": () => import("./../src/templates/artworks-simple.js" /* webpackChunkName: "component---src-templates-artworks-simple-js" */),
  "component---src-templates-blog-index-js": () => import("./../src/templates/blog-index.js" /* webpackChunkName: "component---src-templates-blog-index-js" */),
  "component---src-templates-contacts-js": () => import("./../src/templates/contacts.js" /* webpackChunkName: "component---src-templates-contacts-js" */),
  "component---src-templates-home-js": () => import("./../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-message-js": () => import("./../src/templates/message.js" /* webpackChunkName: "component---src-templates-message-js" */),
  "component---src-templates-tags-js": () => import("./../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

